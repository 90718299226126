<template>
    <div class="mt-3 stage-inner-container-padded">
        <div v-if="loading">
            <b-row v-if="!error">
                <b-col cols="12">
                    <h3>Loading... Hang In There, This Should Only Take A Few Moments</h3>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12">
                    <h3>Error</h3>
                </b-col>
                <b-col cols="12">
                    <h4>{{errorMessage}}</h4>
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-row>
                <b-col cols="12">
                    <h3>Create or Update Records by CSV - Session List</h3>
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="showDisabled"
                        name="checkbox-1"
                        :value="true"
                        :unchecked-value="false"
                        @change="fetchList"
                    >
                        Show Disabled/Hidden Sessions
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col cols="12">
                   <b-button block variant="success" :to="{ path: '/home/upload/start' }">Start New Bulk Import/Update Session</b-button>
                </b-col>
            </b-row>
            <b-row v-for="(row, index) in rows" :key="index">
                <b-col cols="12">
                    <b-row>
                        <b-col cols="10">
                            Session: {{row.added_on}}{{(row.disabled) ? ` DISABLED by ${row.disabled_by} on ${row.disabled_on}`:' '}}{{row.type}} {{row.against_table}} {{row.status}}
                        </b-col>
                        <b-col cols="2">
                            <b-button v-if="row.status == 'INITIAL'" variant="success" :to="{ path: `/home/upload/assign/${row.id}` }">Continue Session</b-button>
                            <b-button v-else-if="row.status == 'ASSIGNED' || row.status == 'VALIDATED'" variant="success" :to="{ path: `/home/upload/issues/${row.id}` }">Continue Session</b-button>
                            <b-button variant="info" v-if="row.status == 'COMPLETED' && row.result_file_id != null" @click="downloadResultCSV(row)">Download Result CSV</b-button>
                            <b-button variant="danger" @click="disableSession(row.id)">Remove Session From List</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');
var fileDownload = require('js-file-download');

export default {
    name: 'BulkInsertUpdateSessionList',
    components:{
    },
    props:{
    },
    data(){
        return{
            loading: true,
            error: false,
            errorMessage: null,
            showDisabled: false,
            rows: []
        }
    },
    methods:{
        disableSession(sessionID){
            butils.instance.delete(process.env.VUE_APP_API_BASE_URL + `/bulk/session/${sessionID}`)
            .then((response)=>{
                console.log(response.data.result);
                this.fetchList();
            })
            .catch((err)=>{
                this.error = true;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    this.errorMessage = "Session Load Response 401 - Try Logging In Again";
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
                }else if(butils.isError404(err)){
                    butils.createToast(this, 'Session List Load Failed', 'The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.', 'danger');
                    this.errorMessage = "The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.";
                }else{
                    if(_.has(err, 'response')){
                        var resp = err.response;
                        this.errorMessage = resp.errors;
                    }else{
                        console.log(err);
                        this.errorMessage = "An Unexpected Error Occured Durring Session Load";
                        butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                    }
                }
            })
        },
        downloadResultCSV(data){
            butils.customInstance.responseType.blob().get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + data.result_file_id)
            .then(async (response) => {
                fileDownload(response.data, `Bulk Import Update Result ${data.id}.csv`);
            })
            .catch((error) => {
                console.log(error);
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else if(butils.isError404(err)){
                    butils.createToast(this, "Failed To Download Import/Update Result CSV", "File Does Not Exist", 'danger', 3600)
                }else{
                    if(_.has(err, 'response')){
                        var resp = err.response;
                        this.errorMessage = resp.errors;
                        butils.createToast(this, "Failed To Download Import/Update Result CSV", "Unable to Download Import/Update Result CSV", 'danger', 3600)
                    }else{
                        butils.createToast(this, "Failed To Download Import/Update Result CSV", "Unable to Download Import/Update Result CSV", 'danger', 3600)
                    }
                }
            });
        },
        fetchList(){
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/bulk/session/list.json?showDisabled=${this.showDisabled}`)
            .then((response)=>{
                console.log(response.data.result);
                this.rows = response.data.result;
                this.loading = false;
            })
            .catch((err)=>{
                this.error = true;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    this.errorMessage = "Session Load Response 401 - Try Logging In Again";
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
                }else if(butils.isError404(err)){
                    butils.createToast(this, 'Session List Load Failed', 'The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.', 'danger');
                    this.errorMessage = "The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.";
                }else{
                    if(_.has(err, 'response')){
                        var resp = err.response;
                        this.errorMessage = resp.errors;
                    }else{
                        console.log(err);
                        this.errorMessage = "An Unexpected Error Occured Durring Session Load";
                        butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                    }
                }
            })
        }

    },
    watch:{

    },
    computed:{
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.fetchList();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>